import {BaseRequest} from "@/request/BaseRequest";

/**
 * 店铺员工管理
 */
class StoreEmployee extends BaseRequest {

    public requestPath: string = '/merchant/store_employee';

    /**
     * 重置密码
     * @param id
     */
    resetPassword(id: string): any {
        return this.post(`${this.requestPath}/passRest`, {id: id})
    }

    /**
     * 设置店长
     */
    setMaster(data: {id: string, bmaster: boolean}): any {
        return this.post(`${this.requestPath}/masterSet`, data)
    }

    /**
     * 设置是否可以登陆
     * @param data
     */
    setManager(data: {id: string, bmanager: boolean}): any {
        return this.post(`${this.requestPath}/managerSet`, data)
    }

    /**
     * 获取角色列表
     */
    getRoles(): any {
        return this.get(`${this.requestPath}/roles`);
    }

    /**
     * 获取员工列表
     * @param data
     */
    getSummaryList(data: any): any {
        return this.post(`${this.requestPath}/summaryList`, data)
    }

}

const c = new StoreEmployee();
export {c as StoreEmployee};