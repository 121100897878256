




































































































































import {StoreEmployee} from "@/request/merchant/StoreEmployee";
import {StoreInfo} from "@/request/merchant/StoreInfo";

export default {
    data() {
        let self: any =this;
        return {
            modal: StoreEmployee,
            pwdModal: {
                show: false,
                pwd: ''
            },
            search: [
                {
                    type: 'Input',
                    label: '姓名',
                    key: 'name',
                    width: '120px',
                    placeholder: '请填写姓名'
                },
                {
                    type: 'Input',
                    label: '账号',
                    key: 'acc',
                    width: '120px',
                    placeholder: '请填写账号'
                },
                {
                    type: 'Select',
                    label: '门店',
                    key: 'storeId',
                    width: '160px',
                    clearable: true,
                    items() {
                        return new Promise(resolve => {
                            StoreInfo.getAll().then((body: any) => {
                                let data: any = body.data;
                                resolve(data.map((j: any) => {
                                    return {
                                        label: j.name,
                                        value: j.id
                                    }
                                }));
                            })
                        })
                    }
                }
            ],
            storeList: [],
            roleTypes: [],
            formData: {
                name: '',
                acc: '',
                gender: 1,
                roleType: '',
                storeId: '',
                career: '',
                email: '',
                image: '',
                introduce: '',
                wx: '',
                liveImage: []
            },
            formRules: {
                name: {required: true, trigger: 'blur'},
                acc: {required: true, trigger: 'blur'},
                roleType: {required: true, type: 'number', trigger: 'blur'},
                storeId: {required: true, trigger: 'blur'},
            },
            columns: [
                {
                    title: '个人形象',
                    key: 'image',
                    minWidth: 120,
                    render: (h: any, params: any) => {
                        return h('img', {
                            style: {
                                borderRadius: '3px',
                                height: '40px',
                                width: '40px',
                                marginTop: '5px'
                            },
                            attrs: {
                                src: params.row.image
                            }
                        })
                    }
                },
                {
                    title: '姓名',
                    key: 'name',
                    minWidth: 120
                },
                {
                    title: '账号',
                    key: 'acc',
                    minWidth: 140
                },
                {
                    title: '门店名称',
                    key: 'storeName',
                    minWidth: 120
                },
                {
                    title: '身份类型',
                    key: 'roleTypeStr',
                    minWidth: 120
                },
                {
                    title: '职位',
                    key: 'career',
                    minWidth: 120
                },
                {
                    title: '电子邮箱',
                    key: 'email',
                    minWidth: 140,
                    tooltip: true
                },
                {
                    title: '性别',
                    key: 'gender',
                    minWidth: 100,
                    render: (h: any, p: any) => h('p', p.row.gender === 0?'未知': p.row.gender === 1? '男': p.row.gender === 2? '女': '-')
                },
                {
                    title: '微信号',
                    key: 'wx',
                    minWidth: 140
                }
            ]
        }
    },
    created() {
        let self: any =this;
        StoreInfo.getAll().then((body: any) => {
            if (body['code'] ===0) {
                self.storeList = body.data;
            }
        });
        StoreEmployee.getRoles().then((body: any) => {
            if (body.code === 0) {
                self.roleTypes = body.data;
            }
        })
    },
    methods: {
        createNew() {
            let self: any = this;
            delete self.formData.id;
            self.$refs['el-editor'].setHtml('');
        },
        edit(row: any) {
            let self: any = this;
            self.formData = row;
            self.$refs['el-editor'].setHtml(row.introduce);
        },
        resetPassword(row: any) {
            let self: any = this;
            self.$Modal.confirm({
                title: '提示',
                content: '确定重置密码？',
                onOk() {
                    StoreEmployee.resetPassword(row.id).then((body: any) => {
                        if (body.code === 0) {
                            self.pwdModal.show = true;
                            self.pwdModal.pwd = body.data;
                            // self.$refs['el-table'].flush();
                        }
                    })
                }
            })
        },
        setMaster(row: any) {
            let self: any = this;
            self.$Modal.confirm({
                title: '提示',
                content: '指定为店长？',
                okText: '授权',
                cancelText: '取消授权',
                onOk() {
                    StoreEmployee.setMaster({id: row.id, bmaster: true}).then((body: any) => {
                        if (body.code === 0) {
                            self.$Message.info('授权成功');
                            self.$refs['el-table'].flush();
                        }
                    })
                },
                onCancel() {
                    StoreEmployee.setMaster({id: row.id, bmaster: false}).then((body: any) => {
                        if (body.code === 0) {
                            self.$Message.info('已取消授权');
                            self.$refs['el-table'].flush();
                        }
                    })
                }
            })
        },
        setManager(row: any) {
            let self: any = this;
            self.$Modal.confirm({
                title: '提示',
                content: '登陆授权',
                okText: '授权',
                cancelText: '取消授权',
                onOk() {
                    StoreEmployee.setManager({id: row.id, bmanager: true}).then((body: any) => {
                        if (body.code === 0) {
                            self.$Message.info('授权成功');
                            self.$refs['el-table'].flush();
                        }
                    })
                },
                onCancel() {
                    StoreEmployee.setManager({id: row.id, bmanager: false}).then((body: any) => {
                        if (body.code === 0) {
                            self.$Message.info('已取消授权');
                            self.$refs['el-table'].flush();
                        }
                    })
                }
            })
        },
        submit() {
            let self: any = this;
            let params = {...self.formData};
            self.$refs['el-table'].doSubmitWithValidate(params);
        }
    }
}
